import React from 'react';

import { isPrBranch } from '../utils/client';
export const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

const colors = {
  green: '#139982',
  lightGreen: '#61B195',
  veryLightGreen: '#c7d9d3',
  backgroundGreen: 'rgba(19, 153, 130, 0.25)',
  yellow: '#E2A816',
  orange: '#F99746',
  orangeDark: '#E58332', // darker than orange
  translucentOrange: '#F9974699',
  grey3: '#828282',
  grey4: '#5B5B5A',
  lighGrey: '#F1F1F1',
  veryLightGrey: '#f5f5f5',
  pastelBlue: '#A1C6DA',
  pastelBrown: '#CCC3BA',
  pastelGreen: '#9EBEB8',
  pastelBeige: '#F0CFC4',
  pastelPurple: '#BE9EBB',
  pastelYellow: '#F0E1C4',
  grey: '#d9d9d9',
  purple: '#A97CFF',
  black: '#282F33',
  darkGrey: 'rgba(40,47,51,0.5)',
  blueDark: '#2F4A67',
  paleBeige: '#FBF7F2',
  beige: '#F9F3EB',
  beigeDark: '#F3E8D8',
  beigeTransparent: 'rgba(249, 243, 235,0.7)',
  red: '#D84C42',
  veryLightRed: '#F9E8E7',
  feideBlue: '#1F4698',
  blue: 'rgba(51, 160, 221, 1)',
  blueLight: 'rgba(51, 160, 221, 0.1)',
  translucentBlack: 'rgba(0, 0, 0, 0.7)',
  translucentWhite: 'rgba(255, 255, 255, 0.3)',
  translucentWhite2: 'rgba(255, 255, 255, 0.6)',
  translucentWhite3: 'rgba(255, 255, 255, 0.2)',
  betaYellow: '#ffb800',
  pink: '#ff00ff',
  eraserPink: '#EA8986',
  aqua: '#00FFFF',
  orangeGradient: 'linear-gradient(90deg, #FFA55B 59.9%, #FFC107 100% )',
};

const theme = {
  colors: {
    topbar: isPrBranch() ? colors.purple : colors.paleBeige,
    paleBeige: colors.paleBeige,
    landingPageTopbar: isPrBranch() ? colors.purple : colors.green,
    background: colors.beige,
    backgroundTransparent: colors.beigeTransparent,
    orange: colors.orange,
    divider: colors.grey,
    green: colors.green,
    grey: colors.grey,
    lightGreen: colors.lightGreen,
    veryLightGreen: colors.veryLightGreen,
    backgroundGreen: colors.backgroundGreen,
    primary: colors.green,
    purple: colors.purple,
    blue: colors.blue,
    blueLight: colors.blueLight,
    aqua: colors.aqua,
    pink: colors.pink,
    blueDark: colors.blueDark,
    white: '#fff',
    black: colors.black,
    blackPure: '#000',
    beigeDark: colors.beigeDark,
    iconGrey: colors.grey3,
    red: colors.red,
    veryLightRed: colors.veryLightRed,
    feideBlue: colors.feideBlue,
    greyLight: colors.lighGrey,
    creatorBackgroundGrey: colors.veryLightGrey,
    creatorTopbarGradient: colors.orangeGradient,
    orangeGradient: colors.orangeGradient,
    greyDark: '#575761',
    greyVeryDark: colors.grey4,
    translucentLightBlack: 'rgba(40,47,51,0.85)',
    fadedblack: 'rgba(40,47,51,0.4)',
    fadedwhite: 'rgba(255,255,255,0.4)',
    translucentBlack: colors.translucentBlack,
    translucentWhite: colors.translucentWhite,
    lessTranslucentWhite: colors.translucentWhite2,
    moreTranslucentWhite: colors.translucentWhite3,
    translucentOrange: colors.translucentOrange,
    orangeDark: colors.orangeDark,
    yellow: colors.yellow,
    betaYellow: colors.betaYellow,
    veryLightYellow: colors.pastelYellow,
    eraserPink: colors.eraserPink,
    text: {
      light: '#bdbdbd',
      dark: '#4F4F4F',
      medium: '#A9A8A8',
      darkGrey: colors.darkGrey,
      grey: 'rgba(40,47,51,0.5)',
    },
    chart: {
      p95: colors.pastelBlue,
      p99: colors.pastelGreen,
      avg: colors.orange,
      globalP95: colors.pastelBrown,
      globalP99: colors.pastelPurple,
    },
    avatar: [
      colors.pastelGreen,
      colors.pastelPurple,
      colors.pastelBrown,
      colors.pastelBlue,
      colors.pastelBeige,
      colors.pastelYellow,
    ],
  },
  device: {
    mobileS: `(max-width: ${size.mobileS})`,
    mobileM: `(max-width: ${size.mobileM})`,
    mobileL: `(max-width: ${size.mobileL})`,
    tablet: `(max-width: ${size.tablet})`,
    laptop: `(max-width: ${size.laptop})`,
    laptopL: `(max-width: ${size.laptopL})`,
    desktop: `(max-width: ${size.desktop})`,
    desktopL: `(max-width: ${size.desktop})`,
  },
  effects: {
    floatingCreatorElementShadow:
      '0px 1.6px 3.6px rgba(0, 0, 0, 0.13), 0px 0.3px 0.9px rgba(0, 0, 0, 0.11)',
    floatingCreatorElementShadowHovered:
      '0px 0.6px 1.6px rgba(0, 0, 0, 0.13), 0px 0.2px 0.5px rgba(0, 0, 0, 0.11)',
    disabledElementShadow:
      '0px 0.6px 1px rgba(0, 0, 0, 0.13), 0px 0.3px 0.9px rgba(0, 0, 0, 0.11)',
    floatingCreatorElementBorderRadius: '15px',
    defaultTransition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
    defaultShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    magicFloatingShadow:
      '0px 2.6px 5.6px rgba(0, 0, 0, 0.13), 0px 0.9px 1.3px rgba(0, 0, 0, 0.11)',
    magicFloatingShadowHovered:
      '0px 3.6px 15px rgba(0, 0, 0, 0.13), 0px 1.3px 20px rgba(0, 0, 0, 0.11)',
  },
};

export const notificationStyles: React.CSSProperties = {
  borderRadius: 10,
};

export default theme;
